<template lang="pug">
    .container.special-page-container
        .background-container
        .special-page-main-content
            .top-card
                router-link(to="/" tag="span" class="top-img-container")
                    .img-container
                        a
                            .logo
                h1.title Vigili del fuoco volontari di Molinella
            .bottom-page-info-container
                .top-container
                    .call-card
                        .left-section
                            .call-icon
                                .call
                        .right-section
                            .subtitle Per richieste d'intervento chiamare il
                            .big-text 112
                .bottom-container
                    ul.first-list
                        li
                            .ico.place
                            span Via Aldo Moro 26, Molinella BO
                        li
                            .ico.mail
                            span vigilfuocomolinella@gmail.com
                        li
                            .ico.phone
                            span 051 - 880889
                        li
                            .ico.cellphone
                            span 348 - 5253518
                    ul.social-bar
                        li
                            a(href="https://www.instagram.com/vfvmolinella/" target="_blank")
                                .instagram
                        li
                            a(href="https://www.facebook.com/vvfmolinella/" target="_blank")
                                .facebook

</template>

<script>
export default {
    name: 'Antonino',
    data() {
        return {
        }
    }
}
</script>

<style lang="sass" scoped>
@import "../assets/global"
$icon-size: 24px

.place
    +icon($icon-size, 240px)
    +breakpoint('<phone')
        +icon($icon-size/1.5, 256px)

.mail
    +icon($icon-size, 191px)
    +breakpoint('<phone')
        +icon($icon-size/1.5, 220px)

.phone
    +icon($icon-size, 481px)
    +breakpoint('<phone')
        +icon($icon-size/1.5, 430px)

.cellphone
    +icon($icon-size, 215px)
    +breakpoint('<phone')
        +icon($icon-size/1.5, 238px)

.instagram
    +icon($icon-size*.8, 268px)

.facebook
    +icon($icon-size*.8, 289px)

.youtube
    +icon($icon-size*.8, 309px)

.big-text
    margin-top: .2em
    font-size: 2.5em
    font-weight: 700
    
.top-container,
.bottom-container,
.top-card,
.top-img-container > .img-container
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)

.call-card
    +flexbox(center, center, row, nowrap)
    font-size: 25px
    +breakpoint("<tablet")
        font-size: 20px

    .left-section
        +flexbox(center, center, column, nowrap)
        padding: 1em
        height: 5em

        .call-icon
            +flexbox(center, center, column, nowrap)
            border-radius: 50%
            background: #F44336
            padding: .5em

            .call
                +icon(24px, 481px)
                background: #fff

h1.title
    font-size: 2em
    padding-top: 1em
    +breakpoint('<phone')
        font-size: 1.65em

.top-img-container
    position: relative
    top: 0.5em
    >.img-container
        width: 100%
        height: 5em
        width: 5em
        margin: 0 1.2em
        background-color: #fff
        border-radius: 3em
        +breakpoint('<tablet')
            margin: 0 auto
        .logo
            +sprite(9px, 1376px, 182px)
            position: relative
            height: 100%

.container
    padding: 0 !important

.special-page-container
    position: relative
    min-height: 100vh
    width: 100%
    background-color: #000

    .top-card
        border: 1px solid rgba(0,0,0,.14)
        border-radius: 5px
        background-color: #fff
        +flexbox(center, center, column, nowrap)
        height: 130px
        padding: 0 20px 70px
        +breakpoint('<phone')
            width: 90%
            margin: 0 auto

    .background-container
        z-index: 0
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-image: url(https://vfvmolinella.s3.eu-central-1.amazonaws.com/static/mixed/cover.jpeg)
        background-repeat: no-repeat
        background-size: cover
        filter: blur(4px)
        +breakpoint('tablet')
            position: fixed

    .special-page-main-content
        z-index: 1
        height: 100vh
        +flexbox(center, stretch, column, nowrap)
        +breakpoint('<tablet')
            height: auto
            margin: 100px 0 40px
        +breakpoint('<phone')
            margin: 100px 0 50px

        .bottom-page-info-container
            position: relative
            width: auto
            margin-top: 40px
            +flexbox(center, stretch, row, nowrap)
            +breakpoint('<tablet')
                +flexbox(center, center, column, nowrap)

            .top-container
                +flexbox(center, center, column, nowrap)
                width: auto
                border: 1px solid rgba(0,0,0,.14)
                border-radius: 5px
                padding-right: 24px
                padding-bottom: 14px
                background-color: #fff
                margin-right: 20px
                +breakpoint('<tablet')
                    margin-right: 0
                    width: 90%

            .bottom-container
                +flexbox(center, center, column, nowrap)
                background-color: #fff
                border: 1px solid rgba(0,0,0,.14)
                border-radius: 5px
                padding: 20px
                margin-left: 20px
                +breakpoint('<tablet')
                    margin-left: 0
                    margin-top: 40px
                    width: 90%

                ul.first-list

                    li
                        +flexbox(flex-start, center, row, nowrap)
                        margin: .7em 0
                        font-size: .8em
                        letter-spacing: .05em
                        +breakpoint('<phone')
                            font-size: .9em
                            margin: 1.1em 0

                        span
                            font-weight: 600
                            letter-spacing: .7px
                            color: rgba(0,0,0,0.74)

                        .ico
                            margin-right: .8em
                            background: #212121
                            +breakpoint('<tablet')
                                font-size: 1.8em

                .social-bar
                    +flexbox(flex-start, center, row, nowrap)

                    li
                        +flexbox(flex-start, center, row, nowrap)
                        margin: 1em .5em

                        .instagram, .facebook, .youtube
                            background: #212121

</style>
