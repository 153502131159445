<template lang="pug">
    .container
        h1.title L'ODV

        .description
            .subtitle La nostra Associazione “Vigili del Fuoco Volontari Molinella Friends ODV” - C.so Giuseppe Mazzini 381 – 40062 Molinella Bo, sostiene il locale distaccamento dei Vigili del Fuoco Volontari di Molinella, composto da 21 ragazzi, esclusivamente volontari, abilitati al soccorso tecnico urgente dal Ministero degli Interni. Due automezzi sono in dotazione al distaccamento, sono quotidianamente usati insieme a complesse attrezzature, nelle più disparate tipologie di soccorso. Premesso che l’Associazione “Vigili del Fuoco Volontari Molinella Friends” ha come scopo, Organizzare manifestazioni, attività di raccolta fondi e campagne di sensibilizzazione atte a sviluppare le attività istituzionali. Premesso che il Ministero degli Interni offre pochissime risorse e soprattutto destinate ai distaccamenti di ruolo, grazie alle risorse delle donazioni spontanee, andremo ad acquistare attrezzature, mezzi moderni o mancanti, che abitualmente i Vigili del Fuoco Volontari di Molinella usano nelle proprie operazioni di soccorso, importanti per la propria incolumità e velocizzare il soccorso tecnico urgente.
            a.download-button(href="https://vfvmolinella.s3.eu-central-1.amazonaws.com/static/documents/Volantino-5x1000-Fronte-Retro.pdf") Scarica il volantino
                .icon
                    .arrow
                    .base
        .sub-link
            a(href="https://vfvmolinella.s3.eu-central-1.amazonaws.com/static/documents/nuovo+statuto+completo.pdf") Lo statuto
            a(@click="$router.push({name: 'Tabella'})") Resoconto delle donazioni
            a(@click="$router.push({name: 'Cinquepermille'})") Progetti realizzati grazie al 5x1000

        projects

        h1.title Come donare
        .flex-container
            .instruction-block
                .element-title Destina al 5x1000
                ol.decimal
                    li.subtitle Compila il modulo 730, il CUD oppure il Modello Unico
                    li.subtitle Firma nel riquadro "sostegno del volontariato e delle altre organizzazioni non lucrative di utilità sociale ..."
                    li.subtitle Scrivi il nostro Codice Fiscale 91374170370 nello spazio apposito
                .subtitle Se ti appoggi ad un commercialista o ad un CAF chiedi di destinare il tuo 5x1000 alla nostra Associazione.
            .instruction-block
                .element-title Altre modalità per donare
                ul
                    li.list-title Sul conto corrente postale
                    li
                        ol.letteral
                            li.subtitle N° 1029243738
                            li.subtitle Intestato a: Vigili del Fuoco Volontari Molinella Friends ODV
                            li.subtitle Causale: Libera donazione
                ul
                    li.list-title Tramite bonifico bancario (Poste Italiane S.P.A.)
                    li
                        ol.letteral
                            li.subtitle IBAN IT88 I076 0102 4000 0102 9243 738
                            li.subtitle Intestato a: Vigili del Fuoco Volontari Molinella Friends ODV
                            li.subtitle Causale: Libera donazione
                ul
                    li.list-title Tramite bonifico bancario (Emilbanca)
                    li
                        ol.letteral
                            li.subtitle IBAN IT48 B070 7236 9200 0000 0201 162
                            li.subtitle Intestato a: Vigili del Fuoco Volontari Molinella Friends ODV
                            li.subtitle Causale: Libera donazione
                ul
                    li.list-title Tramite carta di credito o PayPal cliccando sul tasto sottostante
        
        .donation-button
            form(ref="form" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank")
                input(type="hidden" name="cmd" value="_s-xclick")
                input(type="hidden" name="hosted_button_id" value="YYZ85WHN7ADZQ")
                .left-section(@click="submit")
                    .ico-container
                        .paypal
                .right-section(@click="submit")
                    .upper-text Dona ora con
                    .main-text Pay Pal
        h1.title Chi crede in noi
        .loader-container(v-if="load")
            loader
        .mini-flex-container(v-else)
            .agency-card(v-for="company in companies")
                a(@click="openCompanyWebSite(company.website)" :class="{'cursor-default': company.website === null}")
                    img(:src='company.logo')
</template>

<script>
import Projects from './Projects'
import axios from '../utilities/axios'

export default {
    name: 'Onlus',
    components: {
        Projects
    },
    data() {
        return {
            load: false,
            filterCategory: null,
            companies: []
        }
    },
    mounted() {
        this.getCompanies()
    },
    methods: {
        async getCompanies() {
            this.load = true
            const response = await axios.get('company/')
            this.load = false
            this.companies = response.data
        },
        submit() {
            this.$refs.form.submit()
        },
        openCompanyWebSite(url) {
            if (url !== null) window.open(url, '_blank')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
    @import "./../assets/global"

    .cursor-default
        cursor: default

    .paypal
        +icon(600px, 813px)
        background: #fff

    .description
        +flexbox(center, center, row, wrap)
        margin-top: 3em
        max-width: 50em
        font-size: 17px
        text-align: justify
        letter-spacing: .05em
        line-height: 1.2em
        color: rgba(0, 0, 0, 0.82)
        +breakpoint('<tablet')
            max-width: 90%
        +breakpoint('<small-phone')
            max-width: 95%
            padding: .8em

    .sub-link
        +flexbox(center, center, row, wrap)
        position: relative
        width: 100%
        text-align: center
        +breakpoint('<tablet')
            width: 90%
        a
            cursor: pointer
            margin: 1em
            text-decoration: none
            font-size: .9em
            letter-spacing: .04em
            font-weight: bold
            color: #fff
            background: rgba(30, 136, 229,.7)
            padding: .8em 1em
            border-radius: .3em

    .instruction-block
        font-size: 16.4px
        max-width: 28em
        margin: 2em 4em
        +breakpoint('<tablet')
            max-width: 90%
            width: 90%
            margin: 2em

        ol.decimal
            list-style-type: decimal

        ol.letteral
            list-style-type: lower-alpha

        ul
            padding-top: .5em

            .list-title
                padding-top: .9em
                font-size: .9em
                color: rgba(0, 0, 0, .7)
                font-weight: bold

            li
                .list-link
                    color: #1e88e5
                    font-size: .95em
                    font-weight: bold
                    letter-spacing: .04em

    .donation-button
        +flexbox(center, center, row, nowrap)
        cursor: pointer
        font-size: 17px
        height: 4em
        border-radius: .25em
        margin: 1em 0
        color: #fff
        overflow: hidden
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)
        filter: brightness(100%)
        transition: filter .2s ease
        form
            +flexbox(center, center, row, nowrap)

            .left-section
                +flexbox(center, center, row, nowrap)
                width: 4em
                height: 4em
                background: #3292E7

                .ico-container
                    +flexbox(center, center, row, nowrap)
                    height: 3em
                    width: 3em
                    overflow: hidden

            .right-section
                +flexbox(center, flex-start, column, nowrap)
                height: 4em
                background: #469DE9
                text-align: left
                padding: .2em 1.2em 0
                font-weight: bold

                .upper-text
                    text-transform: uppercase
                    font-size: .65em
                    letter-spacing: .07em

                .main-text
                    font-size: 1.4em
                    line-height: 1.1em

            &:hover
                filter: brightness(85%)

    .mini-flex-container
        +flexbox(center, center, raw, wrap)
        position: relative
        max-width: 60%
        padding: 2em 0 4em
        +breakpoint('<tablet')
            max-width: 90%

        .agency-card
            +flexbox(center, center, raw, nowrap)
            cursor: pointer
            margin: 3em 1.5em
            max-width: 7em
            max-height: 2.4em
            filter: grayscale(95%)
            opacity: .65
            transition: filter .2s ease, opacity .2s ease
            +breakpoint('<phone')
                margin: 2em 1em
                max-width: 6em
                max-height: 1.4em

            a
                img
                    position: relative
                    width: 100%

            &:hover
                filter: grayscale(0%)
                opacity: 1
</style>
